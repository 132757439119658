import './App.css';
import React, { useState, useEffect } from 'react';
import logo from './nri.svg'; // Import the image file

function App() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cityFilter, setCityFilter] = useState('');
  const [loadMore, setLoadMore] = useState(0);
  const [error, setError] = useState(null);
  const [hasMoreResults, setHasMoreResults] = useState(true);

  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const host = 'https://www.nricoupons.com/';
  const url = 'https://us-central1-nri-coupons.cloudfunctions.net/queryCoupons';

  const cities = ['Web', 'Austin, TX', 'Dallas, TX', 'Houston, TX', 'SF Bay Area, CA', 'Los Angeles, CA', 'San Diego, CA', 'New York, NY', 'Washington, DC']; //, 'Phoenix, AZ', 'Seattle, WA',  'Raleigh, NC', 'Charlotte, NC', 'Atlanta, GA', 'Chicago, IL' ];

  useEffect(() => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ region: cityFilter, startnumber: loadMore }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson.data);
        if (responseJson.data.length === 0) {
          setHasMoreResults(false); // No further results available
          setLoading(false);
        }
        else {
          setData(data => [...data, ...responseJson.data]);
          setHasMoreResults(true);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [cityFilter, loadMore]);

  useEffect(() => {

    let input = { region: 'Web', startnumber: loadMore };
    const queryParams = new URLSearchParams(window.location.search);

    // Check if the URL contains a 'Id' parameter
    if (queryParams.has('id')) {
      const id = queryParams.get('id');
      input = { id: id };
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setData(data => [...data, ...responseJson.data]);
        setHasMoreResults(true);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });

  }, []);

  const handleCityChange = event => {
    setData([]);
    setLoading(true);
    setHasMoreResults(true);
    setLoadMore(0);
    setCityFilter(event.target.value);
  };

  const handleLoadMore = () => {
    setLoadMore(loadMore => loadMore + 10); // Increment page number
    setLoading(true);
  };

  // Function to handle click on a div
  const handleDivClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleShare = async () => {
    try {
      // Check if the Web Share API is supported
      if (navigator.share) {
        // Shareable data
        const shareData = {
          title: selectedItem.bizname,
          text: selectedItem.coupon ? selectedItem.coupon : '' + '. ' + selectedItem.couponinfo ? selectedItem.couponinfo : '',
          url: host + '?id=' + selectedItem.id
        };

        // Share the content using the Web Share API
        await navigator.share(shareData);
      } else {
        // Web Share API not supported, provide fallback method
        console.error('Web Share API not supported');
        const url = host + '?id=' + selectedItem.id;
        navigator.clipboard.writeText(url);
        alert('Link Copied');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  }

  return (
    <div className='container'>
      <header className="App-header">
        <div className='coupons-header '>
          <div className="business-image">
            <img src={logo} alt={'NRI Coupons'} />
          </div>
        </div>
      </header>

      <main className='coupons-body'>

        <select className='select-box' value={cityFilter} onChange={handleCityChange}>
          <option value="" disabled>Select your city...</option>
          {cities.map((city, index) => (
            <option key={index} value={city}>{city}</option>
          ))}
        </select>
        {loading ? <div className='spinner'></div> : <div></div>}
        <div className="coupon-container">
          {data.map((item, index) => (
            <div key={index} className="coupon-tile" onClick={() => handleDivClick(item)}>
              <div className="coupon-image">
                <img src={item.image} alt={item.bizname} />
              </div>

              <div>

                <div className="coupon-details">
                  <h3>{item.bizname}</h3>

                  <div className="coupon-info">
                    {item.coupon && (
                      <p><b>{item.coupon}</b></p>
                    )}
                    {/*}
                            {item.expirydate._seconds && (
                              <p>Expiry: <b>{new Date(item.expirydate._seconds * 1000).toLocaleDateString()}</b></p>
                            )}
                            */}
                  </div>

                  {item.couponinfo && (
                    <p className='moreinfo'>{item['couponinfo']}</p>
                  )}
                  {/* Address hyperlink */}
                  {item.address && item.address.includes("www") && (
                    <p className='address'>
                      at <a href={item.address} target="_blank" rel="noopener noreferrer">{item.address}</a>
                    </p>
                  )}
                  {item.address && !item.address.includes("www") && (
                    <p className='address'>
                      <a href={`https://maps.google.com/?q=${item.address}`} target="_blank" rel="noopener noreferrer">{item.address}</a>
                    </p>
                  )}

                </div>

              </div>

            </div>
          ))}
        </div>
        {/* Load More button*/}
        <div>
          {hasMoreResults && (
            <button className="load-more-btn" onClick={handleLoadMore}>Load More..</button>
          )}
          {!hasMoreResults && (
            <button className="load-more-btn" disabled>No coupons found..</button>
          )}
        </div>
      </main>

      {/* Footer */}
      <footer>
        <p>Thanks for visiting! We're dedicated to providing the best deals for the Indian community. For business listings, feedback or issues  contact us.</p>
        <p>Contact: <a href="mailto:support@nricoupons.com">support@nricoupons.com</a></p>
        <p>&copy; 2024 All Rights Reserved</p>
      </footer>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            {/* Display full details of selected item */}
            <img src={selectedItem.image} alt={selectedItem.bizname} width='100%' />
            <h3>{selectedItem.bizname}</h3>
            <div>
              <div>
                {selectedItem.coupon && (
                  <p><b>{selectedItem.coupon}</b></p>
                )}
                {/*
                    {selectedItem.expirydate._seconds && (
                      <h3>Expiry: {new Date(selectedItem.expirydate._seconds * 1000).toLocaleDateString()}</h3>
                    )}
                    */}
              </div>

              <p>{selectedItem.couponinfo}</p>
            </div>
            {/* Address hyperlink */}
            {selectedItem.address && selectedItem.address.includes("www") && (
              <h5>
                at <a href={selectedItem.address} target="_blank" rel="noopener noreferrer">{selectedItem.address}</a>
              </h5>
            )}
            {selectedItem.address && !selectedItem.address.includes("www") && (
              <h5>
                Address: <a href={`https://maps.google.com/?q=${selectedItem.address}`} target="_blank" rel="noopener noreferrer">{selectedItem.address}</a>
              </h5>
            )}
            {/* Add more details as needed */}
            <button onClick={handleShare}>Share</button>

            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>

  );

}

export default App;
